// import React, { useState, useEffect } from "react";
import { formatActivityKey } from "../utils/ActivityUtils";

const Itinerary = ({ tripData }) => {
  return (
    <div className="trip-itinerary">
      <h4>
        Here's your {tripData.number_of_days}-day guide for {tripData.location}
      </h4>
      {/* {visibleDays.map(({ day, activities }) => ( */}
      {Object.entries(tripData.itinerary_data).map(([day, activities]) => (
        <div key={day} className="day-activities">
          <h5>{day}</h5>
          <div>
            {Object.entries(activities).map(([activityKey, activityValue]) => (
              <div className="activity-container">
                <div key={activityKey} className="activity-description">
                  {/* Display the activity key as a title or heading */}
                  <strong>{formatActivityKey(activityKey)}</strong>
                  {/* Directly include the activity description */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: activityValue.description,
                    }}
                  />
                </div>
                {/* Image column */}
                {activityKey.startsWith("sightseeing") &&
                activityValue?.image_url ? (
                  <div className="activity-image">
                    <img src={activityValue.image_url} alt={activityKey} />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Itinerary;
