import React from 'react';

// NotFound component is rendered when a route is not found
const NotFound = () => {

    return (
        <div className='page-not-found'>
            <h1>Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;