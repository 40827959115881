import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const FooterSocials = () => {
  const InstagramUrl = "https://instagram.com/tripgenius_ai/";
  const FacebookUrl = "https://facebook.com/tripgeniusai/";
  const TwitterUrl = "https://twitter.com/trip_genius/";

  return (
    <footer className="app-footer-social">
      <a href={InstagramUrl} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon
          className="social-icon-container"
          icon={faInstagram}
          size="1x"
        />
      </a>

      <a href={FacebookUrl} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon
          className="social-icon-container"
          icon={faFacebook}
          size="1x"
        />
      </a>

      <a href={TwitterUrl} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon
          className="social-icon-container"
          icon={faXTwitter}
          size="1x"
        />
      </a>
    </footer>
  );
};

export default FooterSocials;
