// Define a mapping of activity keys to emojis
const activityEmojis = {
  breakfast: "🥐",
  sightseeing: "🏞️",
  dinner: "🍽️",
};

// Helper function to get emoji by activity key
export const getEmojiForActivity = (activityKey) => {
  return activityEmojis[activityKey] || ""; // Default emoji if key not found
};

// Helper function to convert activity keys to user-friendly titles with emojis
export const formatActivityKey = (activityKey) => {
  // Check if the activity key starts with "sightseeing" and has a number
  if (activityKey.startsWith("sightseeing_")) {
    // Extract the number from the key
    const number = parseInt(activityKey.split("_")[1], 10);
    // Map of numbers to their corresponding emoji representations
    const numberEmojis = [
      "0️⃣",
      "1️⃣",
      "2️⃣",
      "3️⃣",
      "4️⃣",
      "5️⃣",
      "6️⃣",
      "7️⃣",
      "8️⃣",
      "9️⃣",
    ];
    // Convert each digit of the number to its emoji representation
    const numberEmoji = number
      .toString()
      .split("")
      .map((digit) => numberEmojis[parseInt(digit, 10)])
      .join("");
    // Return the formatted string with the number replaced by its emoji representation
    return `Sightseeing ${numberEmoji}`;
  }
  // For other activity keys, return them in a formatted way with the corresponding emoji
  return (
    activityKey.charAt(0).toUpperCase() +
    activityKey.slice(1) +
    " " +
    getEmojiForActivity(activityKey)
  );
};
