import React, { useState, useEffect } from "react";
import { CircleLoader } from "react-spinners";

// Loading messages to display during trip generation
const LoadingMessages = [
  "Hang tight! Your trip guide is on its way...",
  "Just a moment, preparing your journey...",
  "Finding the best spots for you...",
  "Getting your personalized recommendations...",
  "Crunching the numbers for the perfect trip...",
  "Exploring hidden gems just for you...",
  "Almost there, working hard for your best experience...",
];

const Loading = () => {
  // constant state variables
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  // useEffect hook to handle loading messages
  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingMessageIndex(
        (prevIndex) => (prevIndex + 1) % LoadingMessages.length,
      );
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  // Render loading page if required data is not present and streaming has not started
  return (
    <div className="form-feedback">
      <CircleLoader color="#FFDEB1" size={60} />
      {LoadingMessages[loadingMessageIndex]}
    </div>
  );
};

export default Loading;
