import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TripForm from "../components/TripForm";
import ResetInputContext from "../components/ResetInputContext"; // Adjust the path
import Cookies from "js-cookie";

export default function TripFormPage() {
  // Used for routing
  const navigate = useNavigate();

  // Try to get the location from the URL
  const { locationUrl } = useParams();

  // Function to reset the component's state
  const resetState = () => {
    // reset form fields
    setLocation("");
    setStartDate("");
    setNumberOfDays("");

    // Reset error messages and loading state
    setErrorMessage("");
    setErrorMessageInputDays("");
  };

  // State variables for form fields
  const [location, setLocation] = useState("");
  const [locationFromURL, setLocationFromURL] = useState("");
  const [invalidInputMessage, setInvalidInputMessage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");

  // state variables for error messages
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMesssageInputDays, setErrorMessageInputDays] = useState("");

  // Get the CSRF token from the cookie
  const csrftoken = Cookies.get("csrftoken");

  // React Context management for reset function
  const { setReset } = useContext(ResetInputContext);

  useEffect(() => {
    // Set the reset function
    setReset(() => resetState);
    console.log("Reset function set in TripFormPage");
  }, []);

  // Handle URL parameter changes to set location from URL
  useEffect(() => {
    if (locationUrl) {
      // set on both location and locationFromURL for consistency
      setLocation(locationUrl);
      setLocationFromURL(locationUrl);
    }
  }, [locationUrl]);

  // Check if `locationFromURL` is truthy and submit the form automatically
  useEffect(() => {
    if (locationFromURL) {
      handleSubmit();
    }
  }, [locationFromURL]);

  // Function to handle input changes in the form fields
  const handleInputChange = (event, name) => {
    console.log("Field:", name);
    const value = event?.target?.value ?? event;
    console.log("Value:", value);

    // handle input change related to location
    if (name === "location") {
      setLocation(value);
      setInvalidInputMessage("");
    }
    // handle input change related to start date
    else if (name === "start_date") {
      if (value) {
        const offset = value.getTimezoneOffset();
        const UTC_adjusted_value = new Date(
          value.getTime() - offset * 60 * 1000,
        );
        setStartDate(UTC_adjusted_value);
      } else if (!value) {
        setStartDate(null);
      }
    }
    // handle input change related to number of days
    else if (name === "number_of_days") {
      setNumberOfDays(value);
      if (value === "") {
        setErrorMessageInputDays("");
      } else if (value < 1) {
        setErrorMessageInputDays("Set at least 1 day");
      } else if (value > 5) {
        setErrorMessageInputDays("Maximum number of days is currently 5");
      } else {
        setErrorMessageInputDays("");
      }
    }
  };

  // Function to fetch data from the backend
  const validateFormInput = async () => {
    try {
      const response = await fetch(
        "/ai_generator/api/validate_form_submission/",
        {
          method: "POST",
          mode: "same-origin", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify({
            location: location,
            start_date: startDate,
            number_of_days: numberOfDays,
          }),
          credentials: "include", // Important for including cookies
        },
      );

      const response_json = await response.json();
      console.log("Response:", response_json);

      // Check if response is invalid
      if (response.status === 400 && response_json.status === "Invalid") {
        console.log("response.ok: ", response.ok);
        setInvalidInputMessage(response_json.invalid_input_message);
        return false;
      }

      // Check if response is not ok
      if (!response.ok) {
        setErrorMessage("Something went wrong. Please try again."); // catch all error message
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // when valid input set placeID and return True
      //   setPlaceID(response_json.data.place_id);
      return { isValid: true, placeID: response_json.data.place_id };
    } catch (error) {
      console.error("Error fetching data: ", error);
      return false;
      // Handle errors here
    }
  };

  // Function to handle form submission
  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    // Use either `locationFromURL` or `location` for submission
    const locationSubmit = locationFromURL || location;

    // Clear previous error messages
    setErrorMessage("");
    setInvalidInputMessage("");

    // Validate form input
    const { isValid, placeID: receivedPlaceID } = await validateFormInput();
    if (!isValid) {
      // Validation failed, stop here
      return;
    }

    // Google Tag Manager - Push an event to the data layer after form processing is done
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "trip_form_submit",
    });

    //navigate to trip_guide/location page
    navigate(`/trip_guide/${locationSubmit}`, {
      state: {
        location: locationSubmit,
        start_date: startDate,
        number_of_days: numberOfDays,
        place_id: receivedPlaceID,
        fromForm: true,
      },
    });
  };

  // Render the TripFormPage component
  return (
    <div className="form-page">
      <TripForm
        location={location}
        invalidInputMessage={invalidInputMessage}
        startDate={startDate}
        numberOfDays={numberOfDays}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        errorMessage={errorMesssageInputDays}
      />

      {(errorMessage || invalidInputMessage) && (
        <div className="form-feedback">
          {errorMessage ? (
            <div style={{ whiteSpace: "pre-line" }}>{errorMessage}</div>
          ) : invalidInputMessage ? (
            <div style={{ whiteSpace: "pre-line" }}>{invalidInputMessage}</div>
          ) : null}
        </div>
      )}
    </div>
  );
}
