import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      {/* <a href="#" className="footer-link">
        About
      </a> */}
      <Link className="footer-link" to="/">
        Home
      </Link>
      <Link className="footer-link" to="/about">
        About
      </Link>
      {/* <Link className="footer-link" to="/faq">
        FAQ
      </Link> */}
      <Link className="footer-link" to="/privacy">
        Privacy
      </Link>
      <Link className="footer-link" to="/contact">
        Contact
      </Link>
    </footer>
  );
}

export default Footer;
