import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const LocationImage = React.memo(({ location, place_id }) => {
  // state variables
  const [locationImageData, setLocationImage] = useState(null);
  const [attemptCount, setAttemptCount] = useState(0);
  const maxAttempts = 3; // Maximum number of attempts

  const csrftoken = Cookies.get("csrftoken");

  const fetchLocationImage = async () => {
    // check if maximum number of attempts has been reached
    if (attemptCount >= maxAttempts) {
      return; //
    }

    setAttemptCount(attemptCount + 1); // Increment attempt count

    try {
      const response = await fetch("/ai_generator/api/location_image/", {
        method: "POST",
        mode: "same-origin",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify({ location, place_id }),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const response_json = await response.json();
      processLocationImageData(response_json);
    } catch (error) {
      console.error("Error fetching location_image: ", error);
    }
  };

  const processLocationImageData = (response_json) => {
    console.log("Received image:", response_json);
    switch (response_json.status) {
      case "success":
        if (response_json) {
          setLocationImage(response_json.image_url);
        }
        break;
      default:
        console.log("Error processing data."); // Set error message for processing issues
        break;
    }
  };

  useEffect(() => {
    if (location) {
      fetchLocationImage();
      console.log("fetchLocationImage() called with location:", location);
    }
  }, [location]); // Added tripData as a dependency

  return (
    <div>
      {locationImageData ? (
        <img
          src={locationImageData}
          alt="Location"
          className="location-image"
        />
      ) : null}
    </div>
  );
});

export default LocationImage;
