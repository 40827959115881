import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import EmailForm from "../components/EmailForm";
import Cookies from "js-cookie";
import StreamChatGPT from "../components/StreamChatGPT";
import Itinerary from "../components/Itinerary";
import CountryFactsheet from "../components/CountryFactsheet";
import Loading from "../components/Loading";
import ShareItinerary from "../components/ShareItinerary";
import LocationImage from "../components/LocationImage";
import Map from "../components/Map";

const TripResponsePage = () => {
  // Get the location from the location state
  const locationUrlObject = useLocation();

  // set initial trip data from the location state or the URL
  const initialTripData = locationUrlObject.state;

  // state variables
  const [activeTab, setActiveTab] = useState("itinerary");
  const [tripData, setTripData] = useState(initialTripData);
  const [itineraryPresent, setItineraryPresent] = useState(false);
  const [startStreaming, setStartStreaming] = useState(false);
  const [streamingFinished, setStreamingFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  // Memoize the location data for LocationImage component
  const locationData = useMemo(() => {
    return initialTripData.location;
  }, [initialTripData.location]);

  // Get CSRF token from cookies for POST request
  const csrftoken = Cookies.get("csrftoken");

  // Function to fetch data from the backend
  const fetchData = async () => {
    console.log("Fetching data... triggered");
    setIsLoading(true);
    try {
      const response = await fetch(
        "/ai_generator/api/process_form_submission/",
        {
          method: "POST",
          mode: "same-origin", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrftoken,
          },
          body: JSON.stringify(tripData),
          credentials: "include", // Important for including cookies
        },
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const response_json = await response.json();

      // futher processing of received data such as updating tripData
      processReceivedData(response_json);

      // Handle errors here// Handle errors here
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to process the received data
  const processReceivedData = (response_json) => {
    // Similar logic as in the WebSocket onmessage handler
    console.log("Received message:", response_json);

    // Initialize updatedData with the current tripData state
    let updatedData = { ...tripData };

    // Process the data as per your WebSocket logic
    switch (response_json.status) {
      // if contains success
      case "success":
        setTripData((prevData) => ({
          ...prevData,
          ...response_json.data,
        }));

        // check whether itinerary is retrieved from DB
        if (response_json.data.itinerary_data) {
          setItineraryPresent(true);
        } else {
          setStartStreaming(true);
        }

        break;

      case "Not found":
        // Only update error_message_viator if there's a new error message
        if (response_json.viator_products_error) {
          updatedData.error_message_viator =
            response_json.viator_products_error;
        }
        break;

      default:
        // setErrorMessage(response_json.error_message);
        break;
    }
    return updatedData;
  };

  // Place the useEffect here to react to changes in tripData
  useEffect(() => {
    console.log("Updated tripData:", tripData);
    // Any additional logic that needs to run when tripData updates
  }, [tripData]);

  // useEffect hook to set isLoading to true if required data is not present
  useEffect(() => {
    if (!itineraryPresent && !startStreaming && !dataFetched) {
      fetchData();
      setDataFetched(true);
    }
  }, [itineraryPresent, startStreaming, isLoading, dataFetched]); // Only re-run the effect if hasRequiredData changes

  return (
    <div className="trip-response">
      {/* shows different tabs */}
      <div className="tabs">
        <button
          onClick={() => setActiveTab("itinerary")}
          className={activeTab === "itinerary" ? "active" : ""}
        >
          Itinerary
        </button>
        <button
          onClick={() => setActiveTab("factsheet")}
          className={activeTab === "factsheet" ? "active" : ""}
        >
          Country info
        </button>
        <button
          onClick={() => setActiveTab("map")}
          className={activeTab === "map" ? "active" : ""}
        >
          Map
        </button>
      </div>

      {/* location image component */}
      <div>
        <LocationImage location={locationData} place_id={tripData.place_id} />
      </div>

      <div>
        {/* show loading component when waitin for data to be received*/}
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {/* stream component which always renders and doesn't unmount */}
            {startStreaming && (
              <div
                style={{
                  display: activeTab === "itinerary" ? "block" : "none",
                }}
              >
                <StreamChatGPT
                  tripData={tripData}
                  onStreamingFinished={() => setStreamingFinished(true)}
                />
                {streamingFinished && (
                  <ShareItinerary location={tripData.location} />
                )}
                <EmailForm tripData={tripData} />
              </div>
            )}

            {/* itinerary component which only renders when itinerary is present */}
            {itineraryPresent && (
              <div
                style={{
                  display: activeTab === "itinerary" ? "block" : "none",
                }}
              >
                <Itinerary tripData={tripData} />
                <ShareItinerary location={tripData.location} />
                <EmailForm tripData={tripData} />
              </div>
            )}

            <div
              style={{ display: activeTab === "factsheet" ? "block" : "none" }}
            >
              <CountryFactsheet tripData={tripData} />
            </div>
            {/* mapPage showing locations on map */}
            <div style={{ display: activeTab === "map" ? "block" : "none" }}>
              <Map tripData={tripData} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TripResponsePage;
