import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import LoadingFactsheet from "./LoadingFactsheet";

const CountryFactsheet = React.memo(({ tripData }) => {
  // display order factsheet elements
  const displayOrder = [
    "Capital",
    "Population",
    "Area",
    "Currency",
    "Language",
    "Culture",
    "Climate",
    "Habits",
    "Safety",
  ];

  // state variables
  const [factsheetData, setFactsheetData] = useState({}); // Initialized with an empty object
  const [factsheetPresent, setFactsheetPresent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const maxAttempts = 3; // Maximum number of attempts
  const [error, setError] = useState(""); // State for error messages

  const csrftoken = Cookies.get("csrftoken");

  const fetchFactsheet = async () => {
    // check if maximum number of attempts has been reached
    if (attemptCount >= maxAttempts) {
      setError("Maximum number retry attempts reached.");
      return; //
    }

    setAttemptCount(attemptCount + 1); // Increment attempt count
    setIsLoading(true);
    setError("");

    console.log("Attempt", attemptCount);

    try {
      const response = await fetch("/ai_generator/api/factsheet/", {
        method: "POST",
        mode: "same-origin",
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrftoken,
        },
        body: JSON.stringify(tripData),
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const response_json = await response.json();
      processFactsheetData(response_json);
    } catch (error) {
      console.error("Error fetching factsheet: ", error);
      setError("Failed to fetch factsheet."); // Set error message
    } finally {
      setIsLoading(false);
    }
  };

  const processFactsheetData = (response_json) => {
    console.log("Received factsheet:", response_json);
    switch (response_json.status) {
      case "success":
        if (response_json.factsheet) {
          setFactsheetData(response_json.factsheet);
          setFactsheetPresent(true);
        }
        break;
      case "failed":
        setError(response_json.message); // Set error message
        break;
      default:
        setError(
          "Sorry, something went wrong when handling your " +
            "request😕 Please try again...",
        ); // Set error message for processing issues
        break;
    }
  };

  useEffect(() => {
    if (
      !factsheetPresent &&
      !isLoading &&
      tripData.country &&
      attemptCount < maxAttempts
    ) {
      fetchFactsheet();
    }
  }, [factsheetPresent, tripData.country]); // Added tripData as a dependency

  if (isLoading) {
    return <LoadingFactsheet />; // Loading indicator
  }

  if (error) {
    return <div className="error-message">{error}</div>; // Display error message
  }

  if (factsheetPresent) {
    return (
      <div className="country-factsheet">
        <h4>Country insights for {tripData.location}</h4>
        <div className="factsheet-teaser">
          <h5>{factsheetData["Country"]}</h5>
          <p>{factsheetData["Teaser"]}</p>
        </div>
        {displayOrder.map(
          (key) =>
            factsheetData[key] && (
              <div key={key} className="factsheet-items">
                <strong className="factsheet-key">{key}</strong>
                <p>{factsheetData[key]}</p>
              </div>
            ),
        )}
      </div>
    );
  }

  // default return statement
  return <div>No factsheet data available.</div>;
});

export default CountryFactsheet;
