import { useLocation } from "react-router-dom";
import TripResponsePage from "./TripResponsePage";
import TripFormPage from "./TripFormPage";

const TripGuidePage = () => {
  const location = useLocation();
  const fromForm = location.state?.fromForm;

  if (fromForm) {
    // Page loaded from user initiated form submission
    return <TripResponsePage />;
  } else {
    // Page loaded from direct URL navigation
    return <TripFormPage />;
  }
};

export default TripGuidePage;
