import React, { useState } from "react";
import { FaPaperPlane } from "react-icons/fa";

const EmailForm = ({ tripData }) => {
  // define initial state
  const [email, setEmail] = useState("");
  const [email_sent, setEmailSent] = useState(false);
  const [api_response, setApiResponse] = useState(null);
  const [email_dict, setEmailDict] = useState("");

  // send email data to server or do other actions with i
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Google Tag Manager - Push an event to the data layer after form processing is done
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "email_form_submit",
    });

    try {
      const response = await fetch("/ai_generator/api/mail/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          tripData,
        }),
      });

      // check if response from API call is succesful
      if (!response.ok) {
        throw new Error("Failed to send email correctly");
      }

      // if response is successful, retrieve data
      const api_response = await response.json();

      // set state of api_response
      setApiResponse(api_response);

      // ensure email_sent variable is filled and state is set to True or False
      setEmailSent(api_response.email_dict.email_sent);

      // set email_dict
      setEmailDict(api_response.email_dict);

      // catch error
    } catch (error) {
      console.error(error);
    }
  };

  // disable the button when email has been sent
  const isDisabled = email_sent || email === "";

  // clear email_sent state when email input is empty
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (event.target.value === "") {
      setEmailSent(false);
    }
  };

  return (
    <div>
      <div className="form-field-mail">
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              // placeholder="Enter email for trip guide in mailbox"
              placeholder="Receive itinerary by mail?"
              required
            />
            <button
              id="submit_btn_email"
              type="submit"
              disabled={isDisabled}
              className="envelope-button"
            >
              <FaPaperPlane />
            </button>
          </div>
        </form>
      </div>
      {/* provide feedback on whether email has been sent successful */}
      {api_response && (
        <div className="mail-response">
          <div id="email-status">
            {email_sent
              ? email_dict.email_feedback_pos
              : email_dict.email_feedback_neg ||
                email_dict.email_feedback_invalid}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailForm;
