import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import React, { useState } from "react"; // Import useEffect
import Header from "./components/Header";
import FooterSocials from "./components/FooterSocials";
import Footer from "./components/Footer";
import TripFormPage from "./pages/TripFormPage";
import TripGuidePage from "./pages/TripGuidePage";
// import FAQPage from "./pages/FAQPage";
import ContactPage from "./pages/ContactPage";
import PrivacyPage from "./pages/PrivacyPage";
import AboutPage from "./pages/AboutPage";
import NotFound from "./components/NotFound";
import ResetInputContext from "./components/ResetInputContext"; // Adjust the path
import CountryFactsheet from "./components/CountryFactsheet";

function App() {
  const [resetFunction, setResetFunction] = useState(() => {});

  return (
    <ResetInputContext.Provider
      value={{ reset: resetFunction, setReset: setResetFunction }}
    >
      <Router>
        <div className="container trip-style">
          <div className="app">
            <Header></Header>
            <Routes>
              <Route exact path="/" element={<TripFormPage />} />
              <Route
                path="/trip_guide/:locationUrl"
                element={<TripGuidePage />}
              />
              <Route path="/factsheet" element={<CountryFactsheet />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <FooterSocials></FooterSocials>
          </div>
          <div className="spacer"></div> {/* Add this spacer div */}
          <Footer></Footer>
        </div>
      </Router>
    </ResetInputContext.Provider>
  );
}

export default App;
