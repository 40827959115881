import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import { useEffect, useState, useRef } from "react";

const Maps = ({ tripData }) => {
  const defaultPosition = { lat: 53.54992, lng: 10.00678 };
  const [sightseeingDetails, setSightseeingDetails] = useState([]);
  const [infowindowOpen, setInfowindowOpen] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const markerRefs = useRef({});

  console.log("tripData map", tripData);

  // Initialize 'position' for map view
  let position;

  // Ensure tripData exists before accessing its properties
  if (tripData && tripData.itinerary_type === "country") {
    // Access properties with spaces using bracket notation
    position =
      tripData.itinerary_data &&
      tripData.itinerary_data["Day 1"] &&
      tripData.itinerary_data["Day 1"].sightseeing_1 &&
      tripData.itinerary_data["Day 1"].sightseeing_1.coordinates
        ? {
            lat: tripData.itinerary_data["Day 1"].sightseeing_1.coordinates.lat,
            lng: tripData.itinerary_data["Day 1"].sightseeing_1.coordinates
              .long,
          }
        : defaultPosition;
  } else if (tripData && tripData.itinerary_type === "city") {
    // Define 'position' based on the existence of tripData.coordinates
    position = tripData.coordinates
      ? { lat: tripData.coordinates.lat, lng: tripData.coordinates.lng }
      : defaultPosition;
  }

  const extractSightseeingDetails = (itinerary_data) => {
    const details = [];

    Object.values(itinerary_data).forEach((day) => {
      Object.entries(day).forEach(([key, value]) => {
        if (key.startsWith("sightseeing") && value.coordinates) {
          details.push({
            lat: value.coordinates.lat,
            lng: value.coordinates.long, // Note: Adjust for 'long' instead of 'lng'
            title: value.title, // Extract title
            description: value.description, // Extract description
            webURL: value.webURL, // Extract webURL
            image_url: value.image_url, // Extract image_url
          });
        }
      });
    });
    return details;
  };

  const extractSightseeingDetailsStreaming = (landmarks, days) => {
    const maxDetails = days * 3;
    const details = [];

    // Assuming landmarks is an object with an 'attractions' array
    landmarks.attractions?.slice(0, maxDetails).forEach((attraction) => {
      details.push({
        lat: attraction.latitude,
        lng: attraction.longitude, // Adjusted to 'lng' for consistency
        title: attraction.title, // Extract title
        description: "", // Placeholder, as description is not in the provided data structure
        webURL: attraction.webURL, // Extract webURL
        image_url: attraction.thubmnailHiResURL || attraction.thumbnailURL,
        rating: attraction.rating, // Optional: Extract rating
        reviewCount: attraction.reviewCount, // Optional: Extract reviewCount
      });
    });

    return details;
  };

  useEffect(() => {
    if (tripData.itinerary_data) {
      const details = extractSightseeingDetails(tripData.itinerary_data);
      setSightseeingDetails(details);
    } else if (tripData.landmarks) {
      const details = extractSightseeingDetailsStreaming(
        tripData.landmarks,
        tripData.number_of_days,
      );
      setSightseeingDetails(details);
    }
  }, [tripData.itinerary_data, tripData.landmarks]);

  // Function to check if coordinates are valid
  const isValidCoordinate = (detail) => {
    return (
      !isNaN(parseFloat(detail.lat)) &&
      !isNaN(parseFloat(detail.lng)) &&
      !(detail.lat === 0 && detail.lng === 0)
    );
  };

  const mapOptions = {
    mapId: process.env.REACT_APP_GOOGLE_MAPS_MAP_ID,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
  };

  // Function to convert index to emoji
  const indexToEmoji = (index) => {
    const numberEmojis = [
      "0️⃣",
      "1️⃣",
      "2️⃣",
      "3️⃣",
      "4️⃣",
      "5️⃣",
      "6️⃣",
      "7️⃣",
      "8️⃣",
      "9️⃣",
    ];
    return index
      .toString()
      .split("")
      .map((digit) => numberEmojis[parseInt(digit, 10)])
      .join("");
  };

  return (
    <div className="map-container">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API}>
        <Map defaultCenter={position} defaultZoom={10} options={mapOptions}>
          {sightseeingDetails.map((detail, index) => {
            // Only render the marker if the coordinates are valid
            if (isValidCoordinate(detail)) {
              return (
                <AdvancedMarker
                  key={index}
                  position={{ lat: detail.lat, lng: detail.lng }}
                  onClick={() => {
                    setInfowindowOpen(true);
                    setSelectedMarker(index);
                  }}
                  ref={(ref) => {
                    markerRefs.current[index] = ref;
                  }}
                >
                  <Pin
                    background={"#8e3a52"}
                    borderColor={"#ffdeb1"}
                    glyphColor={"#ffdeb1"}
                    scale={1.25}
                  >
                    {/* 👀 */}
                    {indexToEmoji(index + 1)}
                  </Pin>
                  {infowindowOpen && selectedMarker === index && (
                    <InfoWindow
                      anchor={markerRefs.current[index]}
                      maxWidth={200}
                      onCloseClick={() => setInfowindowOpen(false)}
                    >
                      <div className="marker-info-window">
                        <h3>{detail.title}</h3>
                        <img
                          src={detail.image_url}
                          alt={detail.title}
                          className="marker-info-window-image"
                        />
                      </div>
                      <div className="info-window-button-container">
                        {/* Add buttons to navigate the sightseeing activity */}
                        <button
                          className="navigate-button"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the InfoWindow from closing
                            // Construct the Google Maps navigation URL
                            const navigationUrl = `https://www.google.com/maps/dir/?api=1&destination=${detail.lat},${detail.lng}`;
                            // Open the URL in a new tab
                            window.open(navigationUrl, "_blank");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Navigate
                        </button>

                        {/* Add buttons to book the sightseeing activity */}
                        <button
                          onClick={(e) => {
                            e.preventDefault(); // Prevent the InfoWindow from closing
                            // Construct the Google Maps navigation URL
                            const bookUrl = detail.webURL;
                            // Open the URL in a new tab
                            window.open(bookUrl, "_blank");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Book
                        </button>
                      </div>
                    </InfoWindow>
                  )}
                </AdvancedMarker>
              );
            } else {
              return null;
            }
          })}
        </Map>
      </APIProvider>
    </div>
  );
};

export default Maps;
