import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCircleXmark, FaCirclePlus } from "react-icons/fa6";

// used for checking whether a user is on a destop or mobile device
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};

const TripForm = ({
  handleSubmit,
  location,
  startDate,
  numberOfDays,
  handleInputChange,
  errorMessage,
  isLoading,
}) => {
  const windowSize = useWindowSize();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animatedText, setAnimatedText] = useState("");
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(false);

  const words = [
    "Paris?",
    "New York",
    "London?",
    "Amsterdam?",
    "Berlin?",
    "Chicago?",
    "Buenos Aires?",
    "Toronto?",
    "Sydney?",
    "Stockholm?",
    "Barcelona?",
    "Tokyo?",
    "Prague?",
  ];

  useEffect(() => {
    if (isAnimating && words.length > 0) {
      let index = 0;
      const word = words[currentWordIndex];
      setAnimatedText(""); // Initially clear animated text to ensure placeholder is shown at the start

      // Introduce a delay before starting to animate the next word to show the placeholder
      const startAnimationDelay = setTimeout(() => {
        setAnimatedText(word.charAt(index)); // Set the first character of the current word to start animation

        const interval = setInterval(() => {
          index++; // Increment index to get the next character
          if (index < word.length) {
            setAnimatedText((prev) => prev + word.charAt(index));
          } else {
            clearInterval(interval);
            // After completing the word, clear the text and show placeholder before moving to the next word
            setTimeout(() => {
              setAnimatedText("");
              setCurrentWordIndex(
                (prevIndex) => (prevIndex + 1) % words.length,
              ); // Prepare for next word
            }, 2500); // Duration to show the placeholder between words
          }
        }, 100); // Speed of adding characters

        return () => {
          clearTimeout(startAnimationDelay);
          clearInterval(interval);
        };
      }, 2500); // Delay before starting the animation of each word
    }
  }, [isAnimating, currentWordIndex]);

  // Trigger animation for the first word when the component mounts
  useEffect(() => {
    setIsAnimating(true);
  }, []);

  const modifiedHandleInputChange = (e, field) => {
    if (isAnimating) {
      setIsAnimating(false); // Stop animation if user starts typing
      setAnimatedText(""); // Reset animation text
    }
    handleInputChange(e, field);
  };

  return (
    <div className="trip-form">
      <div className="form-header">
        <h2>Plan your next trip!</h2>
      </div>

      <div className="form-group">
        <form id="trigger-form-submit" onSubmit={handleSubmit}>
          {/* location form */}
          <div className="form-field" id="id_location">
            <input
              type="text"
              name="location"
              onChange={(e) => modifiedHandleInputChange(e, "location")}
              value={
                isFocused
                  ? location
                  : isAnimating && animatedText
                  ? `🌍 ${animatedText}`
                  : location
              }
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setIsFocused(false);
                if (!location) setIsAnimating(true); // Optionally restart animation if the user hasn't entered anything
              }}
              placeholder="🌍 Your destination?"
            />
          </div>

          {showAdvancedOptions && (
            <>
              {/* datepicker form */}
              {windowSize > 768 && (
                <div className="form-field" id="id_date_picker">
                  <DatePicker
                    name="start_date"
                    dateFormat="dd/MM/yyyy"
                    selected={startDate}
                    onChange={(e) => handleInputChange(e, "start_date")}
                    placeholderText="📅 When?"
                    minDate={new Date()}
                    popperPlacement="top-end"
                  />
                </div>
              )}

              {/* number_of_days form */}
              <div className="form-field" id="id_number_of_days">
                <input
                  type="number"
                  name="number_of_days"
                  value={numberOfDays}
                  onChange={(e) => handleInputChange(e, "number_of_days")}
                  min="1"
                  max="5"
                  placeholder="#️⃣ of days?"
                />
                <div id="error_msg" style={{ color: "red" }}>
                  {errorMessage}
                </div>
              </div>
            </>
          )}

          {/* more options */}
          <div className="form-field">
            <button
              id="id_more_options"
              type="button"
              onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
              className="more-options-btn"
            >
              {showAdvancedOptions ? "Hide options" : "More options?"}
              {showAdvancedOptions ? <FaCircleXmark /> : <FaCirclePlus />}
            </button>
          </div>

          {/* submit button */}
          <div className="form-field" id="id_button">
            <button
              type="submit"
              className="btn-generate-trip"
              id="submit_btn_trip_form"
              disabled={
                (numberOfDays && (numberOfDays < 1 || numberOfDays > 5)) ||
                isLoading ||
                !location
              }
            >
              {isLoading ? "Loading..." : "Let's go 🚀"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TripForm;
