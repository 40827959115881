import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { useState, useEffect } from "react";
import { ReactTyped } from "react-typed";

const ShareItinerary = ({ location }) => {
  const [shareText, setShareText] = useState("");

  useEffect(() => {
    const updateTextBasedOnScreenSize = () => {
      if (window.innerWidth < 600) {
        setShareText(`Share with your `);
      } else {
        setShareText(`Share itinerary with your `);
      }
    };

    window.addEventListener("resize", updateTextBasedOnScreenSize);

    // Set initial text
    updateTextBasedOnScreenSize();

    // Cleanup
    return () => {
      window.removeEventListener("resize", updateTextBasedOnScreenSize);
    };
  }, [location]);

  // Try to get the location from the URL
  const shareViaWhatsApp = () => {
    const url = window.location.href;
    const message = `Check out this itinerary I created for ${location}: ${url}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl);
  };

  return (
    <div>
      <div className="app-share-itinerary">
        <button className="share-itnerary-container" onClick={shareViaWhatsApp}>
          <span>
            {shareText}
            <ReactTyped
              strings={["travel buddy", "partner", "family", "friends"]}
              typeSpeed={250}
              backSpeed={60}
              loop
            />
          </span>
          <FontAwesomeIcon
            className="social-icon-whatsapp"
            icon={faWhatsapp}
            size="1x"
          />
        </button>
      </div>
      <span className="app-share-separator">or</span>
    </div>
  );
};

export default ShareItinerary;
