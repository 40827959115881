import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ResetInputContext from "./ResetInputContext"; // Adjust the path

const Header = () => {
  const { reset } = useContext(ResetInputContext);

  return (
    <Link className="app-header" to="/" onClick={reset}>
      <img
        src="/static/images/original_logo_symbol_dark_cropped.webp"
        alt="logo"
        className="logo"
      />{" "}
      {/* Add this line */}
      <h1>
        <span className="header-text">Trip Genius</span>
        <span>✨</span>
      </h1>
    </Link>
  );
};

export default Header;
