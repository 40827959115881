import React, { useState, useEffect } from "react";
import { CircleLoader } from "react-spinners";

// Loading messages to display during trip generation
const LoadingMessages = [
  "Tailoring the most important country info about your next stop...",
  "Bringing together cultural gems and key facts...",
  "Assembling country facts for your journey...",
  "Harvesting key insights from across the nation...",
];

const LoadingFactsheet = () => {
  // constant state variables
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);

  // useEffect hook to handle loading messages
  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingMessageIndex(
        (prevIndex) => (prevIndex + 1) % LoadingMessages.length,
      );
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  // Render loading page if required data is not present and streaming has not started
  return (
    <div className="form-feedback">
      <CircleLoader color="#FFDEB1" size={60} />
      {LoadingMessages[loadingMessageIndex]}
    </div>
  );
};

export default LoadingFactsheet;
