import React from "react";

function ContactPage() {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <p>
        In order to get in contact with us for information, feedback or to
        report a bug, please use our social channels or send an email to:
        <a href="mailto:"> info@tripgenius.ai</a>
      </p>
      <br />
    </div>
  );
}

export default ContactPage;
