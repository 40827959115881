import React from "react";

function PrivacyPage() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        We respect the privacy of our users and are committed to protecting it.
        This Privacy Policy outlines our practices concerning the collection,
        use, and sharing of your personal information.
      </p>
      <br />
      <h2>Data Collection</h2>
      <p>We collect the following data to provide a personalized experience:</p>
      <ul>
        <br />
        <li>
          <strong>Analytics Information</strong>
        </li>
        <p>
          Technical/analytical information collected by tripgenius.ai when you
          use the webapp (including if you clicked on links to third-party
          applications), IP addresses, times and methods of your requests,
          browser and operating system types and other usage data such as pages
          you've viewed or interacted with, for how long and the frequency of
          such interactions.
        </p>
        <br />
        <li>
          <strong>Cookies and Site Data</strong>
        </li>
        <p>
          This website uses cookies and similar technologies in the following
          ways when you use tripgenius.ai:
          <br />
          Google Analytics and Hotjar cookies to collect information about how
          visitors use the webapp, which tripgenius.ai uses in turn to help
          improve its services. These cookies collect information such as the
          number of Website users, where users are located when using the webapp
          and the parts of the webapp they visit.
        </p>
      </ul>
      <br />
    </div>
  );
}

export default PrivacyPage;
