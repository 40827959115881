import React from "react";

function AboutPage() {
  return (
    <div className="about-page">
      <h1>About Us</h1>
      <p>
        Welcome to TripGenius.ai - your AI-powered travel tool! We've created
        this webapp to help you plan your next trip in a fast and easy way.
      </p>
      <br />
      <p>
        With Trip Genius you don't need to spend hours to research destinations
        and figuring out what to do. Our AI-tool does all the hard work for you
        - from suggesting unique ideas to creating fully personalized
        itineraries tailored to your preferences. All you need is a few seconds
        to get a perfect plan for your next city break or vacation!
      </p>
      <br />
      <p>
        Don't hesitate to contact us and share any feedback you have - we'd love
        to hear from you how we can improve our product.
      </p>
      <br />
      <p>
        Thank you for being a part of our journey and let the adventures begin!
      </p>
      <br />
    </div>
  );
}

export default AboutPage;
